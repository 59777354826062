{
    "items":{   
        "list":[
            {
                "name":"Jukebox",
                "date":"2023",
                "url":"https://lab.jaredstanley.com/jukebox/",
                "description":"Using AI to generate a 70's-inspired device. Will add push-button mechanics at some point in the future."
            },
            {
                "name":"YT: GIS Maps in Blender",
                "date":"2023",
                "url":"https://www.youtube.com/embed/PmHxBn7F9Fw",
                "description":"Generating 3D maps in Blender 3.5 using GIS map data. I have two vids up - one is for generating maps via a GIS plugin and the other is generating with no plugin."
            },
            {
                "name":"Carnival",
                "date":"2023",
                "url":"https://lab.jaredstanley.com/carnival/",
                "description":"Step Right Up and pull the lever to choose a number 1-10. Built in Blender and Javascript(ThreeJS)"
            },
            {
                "name":"Plaster",
                "date":"2017",
                "url":"https://lab.jaredstanley.com/plaster/",
                "description":"This was an attempt to make watercolor-like textures completely from scratch."
            },
            {
                "name":"Image Gen",
                "date":"2017",
                "url":"https://lab.jaredstanley.com/twitterart/index.html?string=chat",
                "description":"This began as an exploration in visualizing strings - using each character as a parameter in a visualization. I used this for <a href=\"https://twitter.com/donaldtrump_art\">https://twitter.com/donaldtrump_art</a> right after the election."
            },
            {
                "name":"YT: glTF in Three.js",
                "date":"May 2020",
                "url":"https://www.youtube.com/embed/QuTvGpbXCcQ",
                "description":"I made a video about exporting glTF models from Cinema 4D to be used in three.js. There was a plugin but version r22 had this functionality built in to the software, so it was a big deal."
            },
            {
                "name":"Web Game",
                "date":"May 2020",
                "url":"https://lab.jaredstanley.com/jrs",
                "description":"A series of simple games. An exploration in mixing <code>SVG</code> animations and HTML5 <code>canvas</code> interactions.<br/>The games are designed to be as easy as possible, progressively getting slightly more difficult with each one. I tried to use vanilla javascript as much as possible."
            },
            {
                "name":"Perlin Noise",
                "date":"Sept 2022",
                "url":"https://lab.jaredstanley.com/perlin/v1",
                "description":"Exploring Perlin Noise. The noise library I'm using was written by Stefan Gustavson, Optimized by Peter Eastman, converted to JavaScript by Joseph Gentle, and shared with me by Keith Peters. It leads to the question: \"Did I really write this code?\" "
            },
            {
                "name":"glTF lit with HDRI Lighting: three.js",
                "date":"Sept 2018",
                "url":"https://jaredstanley.github.io/",
                "description":"Exploring HDRI lighting in three.js using an HDRI image as the light source on a glTF model. <br/> Click and drag to rotate the model. QR Code to quickly load this onto your phone. Click & Drag to rotate the model."
            },
            {
                "name":"Interactive 3D Globe",
                "date":"2018",
                "url":"https://jaredstanley.github.io/globe/",
                "description":"Loading in a glTF and animating with Javascript in three.js. Exploring controlling the animation in Cinema 4D vs. in Javascript."
            },
            {
                "name":"Scroll-Controlled Lottie",
                "date":"Aug 2020",
                "url":"https://lab.jaredstanley.com/lottie/scrollcontrol/",
                "description":"Controlling a Lottie animation with the browser scroll. <br/> The bitmap image was base64-encoded and included into a single .json file; this approach gives full control of the design with the designer, and requires dev to upload a single file - as expected with a jpg or video file.<br/>Designed & Animated by me right before the 2020 election."
            },
            {
                "name":"Scroll-Controlled Three.js",
                "date":"Aug 2020",
                "url":"https://lab.jaredstanley.com/scroller/",
                "description":"Controlling a glTF model in three.js with the browser scroll."
            },
            {
                "name":"Name Animation",
                "date":"Jun 2018",
                "url":"https://lab.jaredstanley.com/nameanim/",
                "description":"Lottie animtion; wanted to try a complex, detailed animation so just did my name."
            },
            {
                "name":"Spin!",
                "date":"Apr 2018",
                "url":"https://lab.jaredstanley.com/spin",
                "description":"Rendering exploration. <br/>As the image rotates faster, Colors and patterns begin to appear that aren't there."
            },
            {
                "name":"Article: Pure Customer Stories",
                "date":"2018",
                "url":"https://medium.com/@jaredstanley/pure-customer-stories-a-case-study-aaa7f3ff6a51",
                "description":"An article about the Customer Stories section of Pure Storage's site."
            },
            {
                "name":"Web AR Demo",
                "date":"2017",
                "url":"https://lab.jaredstanley.com/ar",
                "description":"Exploring WebAR. <br/> open the link, take a pic w your phone. Pull that pic up on your phone and make sure it's visible on your computer's camera."
            },
            {
                "name":"San Francisco Model",
                "date":"2016",
                "url":"https://lab.jaredstanley.com/sf3d",
                "description":"First exploration of a model - an .OBJ file built in Cinema4D, loaded in three.js. <br/> This was going to be a game but became abandonware."
            }
           
        ]
    }
}

           
    