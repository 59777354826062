
// import App from './App';
import labItems from './labitems.json';

window.onload = () => {
    init();
  }

//

//
// import Lottie from 'lottie-web';

let projectsArr=[];
let buttons = [];
let init = () =>{
    // console.log("init");
        buildInfoPanel();
        buildNav();
        
        // raf();
}
let buildInfoPanel = () =>  {
    let infoBtn = document.querySelector('#info'); 
    let infoPanel = document.querySelector('#infoPanel');
    infoBtn.addEventListener('click', function(e){
        
        infoPanel.classList.toggle('hidden');   
        console.log("clicked");
    })
};
let buildNav = () => {
    console.log("buildNav called");
    let c = document.querySelector('#content'); 
    let nav = document.querySelector('#mainNav');
    let count = 0;
    labItems.items.list.forEach(itm => {
        console.log("item found");
        let navItm = document.createElement("div");
        navItm.data = itm;
        navItm.setAttribute("id", "navItem"+count);
        navItm.setAttribute("class", "navItem");
        let title = document.createElement("div");
        title.setAttribute("class","itemTitle");
        let titleTxt = document.createTextNode(itm.name);
        title.appendChild(titleTxt);
        navItm.appendChild(title);
        //
        let d = document.createElement("div");
        d.setAttribute("class","itemDate");
        let dTxt = document.createTextNode(itm.date);
        d.appendChild(dTxt);
        navItm.appendChild(d);
        //
        navItm.addEventListener('click', function(e){
            // console.log(this.data.url)
            deactivate();
            c.src = this.data.url;
            this.classList.add('active');
            populateInfoPanel(this.data);
        })

        nav.appendChild(navItm);
        // let proj = new Item(itm);
        // proj.init();
        projectsArr.push(navItm);
        count++;
    })
    
    let populateInfoPanel = (d) => {
        let txt = document.querySelector('.description');
        txt.innerHTML = `<strong>${d.name}</strong> <br/> ${d.description}`;
    }

    let deactivate = () => {
        projectsArr.forEach(itm => itm.classList.remove("active")); 
    }
    
    populateInfoPanel({name:"Jared Stanley",
    description:"CyberSpace"} );
    c.src = "https://lab.jaredstanley.com/worldww/"
}
